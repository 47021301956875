import React from "react";
import { useOverrides, Override, SocialMedia } from "@quarkly/components";
import { Text, LinkBox, Box, Image, Button, Icon, Link, Section } from "@quarkly/widgets";
import QuarklycommunityKitMobileSidePanel from "./QuarklycommunityKitMobileSidePanel";
import { FiMenu } from "react-icons/fi";
import { GiFairyWings } from "react-icons/gi";
const defaultProps = {
	"sm-padding": "8px 0 8px 0",
	"quarkly-title": "Header-3",
	"font": "16px --fontFamily-googleLora"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"padding": "12px 0",
			"justify-content": "space-between",
			"align-items": "center",
			"flex-direction": "row",
			"width": "30%",
			"sm-width": "50%",
			"sm-align-items": "center",
			"sm-flex-direction": "row",
			"sm-justify-content": "flex-start",
			"md-width": "50%",
			"lg-width": "70%",
			"md-justify-content": "flex-start",
			"text-align": "left"
		}
	},
	"linkBox": {
		"kind": "LinkBox",
		"props": {
			"flex-direction": "row",
			"href": "/index",
			"display": "flex",
			"grid-gap": "12px"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0",
			"md-margin": "0px 0 0px 0",
			"text-align": "left",
			"font": "normal bold 16px/1.5 --fontFamily-googleLora",
			"sm-margin": "0px 0 0px 0",
			"display": "block",
			"children": "REG NO: IN-UP38492236005292U"
		}
	},
	"image": {
		"kind": "Image",
		"props": {
			"src": "https://uploads.quarkly.io/670e4a9e1b94b1002431b398/images/logo_ashram-removebg.png?v=2024-10-15T11:00:58.770Z",
			"display": "block",
			"width": "110px",
			"height": "80px"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"padding": "12px 0",
			"justify-content": "space-between",
			"align-items": "center",
			"flex-direction": "row",
			"width": "35%",
			"sm-width": "50%",
			"sm-align-items": "center",
			"sm-flex-direction": "row",
			"sm-justify-content": "flex-start",
			"md-width": "50%",
			"lg-width": "70%",
			"md-justify-content": "flex-start",
			"text-align": "left",
			"border-color": "#506527"
		}
	},
	"linkBox1": {
		"kind": "LinkBox",
		"props": {
			"flex-direction": "row",
			"href": "/index",
			"display": "flex",
			"grid-gap": "12px"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0",
			"md-margin": "0px 0 0px 0",
			"text-align": "left",
			"font": "normal bold 22px/1.5 --fontFamily-googleLora",
			"sm-margin": "0px 0 0px 0",
			"display": "block",
			"color": "#506527",
			"children": "SHREE RAM RAGHUVAR ASHRAM"
		}
	},
	"quarklycommunityKitMobileSidePanel": {
		"kind": "QuarklycommunityKitMobileSidePanel",
		"props": {
			"menuPosition": "full",
			"breakpoint": "lg",
			"width": "20%",
			"sm-width": "50%",
			"md-width": "50%",
			"lg-width": "30%"
		}
	},
	"quarklycommunityKitMobileSidePanelOverride": {
		"kind": "Override",
		"props": {
			"slot": "Children",
			"md-display": "flex",
			"text-align": "right"
		}
	},
	"quarklycommunityKitMobileSidePanelOverride1": {
		"kind": "Override",
		"props": {
			"slot": "Content",
			"padding": "0px 0px 0px 0px",
			"background": "rgba(255, 255, 255, 0)",
			"lg-background": "#ffffff",
			"lg-margin": "0px 0px 0px 0px"
		}
	},
	"quarklycommunityKitMobileSidePanelOverride2": {
		"kind": "Override",
		"props": {
			"slot": "Button Text",
			"font": "normal 600 16px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
			"text-transform": "uppercase",
			"letter-spacing": "1px",
			"sm-font": "normal 600 14px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
			"sm-margin": "0px 2px 0px 0px",
			"lg-margin": "0px 0px 0px 0px"
		}
	},
	"quarklycommunityKitMobileSidePanelOverride3": {
		"kind": "Override",
		"props": {
			"slot": "Button Icon :closed",
			"category": "fi",
			"icon": FiMenu,
			"size": "32px",
			"padding": "5px 7px 5px 7px",
			"border-radius": "50px"
		}
	},
	"quarklycommunityKitMobileSidePanelOverride4": {
		"kind": "Override",
		"props": {
			"slot": "Button Icon",
			"width": "28px",
			"height": "28px",
			"category": "fi",
			"icon": FiMenu,
			"color": "--dark",
			"size": "24px",
			"lg-width": "32px",
			"lg-height": "32px"
		}
	},
	"quarklycommunityKitMobileSidePanelOverride5": {
		"kind": "Override",
		"props": {
			"slot": "Cross",
			"lg-width": "32px",
			"lg-height": "32px",
			"size": "32px",
			"top": "24px",
			"right": "24px"
		}
	},
	"button": {
		"kind": "Button",
		"props": {
			"background": "#ffffff",
			"font": "normal bold 16px/1.5 --fontFamily-googleLora",
			"color": "#000000",
			"white-space": "pre-wrap",
			"border-width": "1px",
			"border-style": "solid",
			"margin": "0px 10px 0px 0px"
		}
	},
	"button1": {
		"kind": "Button",
		"props": {
			"font": "normal bold 16px/1.5 --fontFamily-googleLora",
			"color": "#000000",
			"background": "--color-secondary",
			"border-width": "1px",
			"border-radius": "2px",
			"border-style": "solid"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"align-items": "center",
			"lg-justify-content": "center",
			"lg-align-items": "flex-start",
			"justify-content": "flex-end",
			"display": "flex",
			"lg-flex-direction": "column",
			"lg-margin": "0px auto 0px auto",
			"lg-min-width": "300px",
			"lg-max-width": "1280px",
			"lg-width": "90%",
			"lg-padding": "24px 0px 48px 0px"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"display": "none",
			"lg-width": "100%",
			"lg-margin": "0px 0px 24px 0px",
			"lg-display": "flex",
			"lg-padding": "12px 0px 12px 0px"
		}
	},
	"linkBox2": {
		"kind": "LinkBox",
		"props": {
			"flex-direction": "row",
			"href": "/index",
			"display": "flex",
			"grid-gap": "12px"
		}
	},
	"icon": {
		"kind": "Icon",
		"props": {
			"category": "gi",
			"icon": GiFairyWings,
			"color": "#6d32ec",
			"size": "37px"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0",
			"md-margin": "0px 0 0px 0",
			"text-align": "left",
			"font": "--lead",
			"sm-margin": "0px 0 0px 0",
			"display": "block",
			"children": "Company"
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"width": "25%",
			"display": "none",
			"lg-width": "100%",
			"lg-flex-direction": "column",
			"lg-align-items": "flex-start",
			"lg-display": "flex",
			"justify-content": "space-around",
			"align-items": "center",
			"flex-wrap": "wrap",
			"lg-margin": "0px 0px 0px 0px"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"font": "--lead",
			"padding": "10px 0px 10px 0px",
			"margin": "0px 0px 6px 0px",
			"href": "tel:12345678",
			"text-decoration-line": "initial",
			"color": "--dark",
			"lg-margin": "0px 0px 24px 0px",
			"children": "+1(234)567-89-00"
		}
	},
	"socialMedia": {
		"kind": "SocialMedia",
		"props": {
			"instagram": "https://instagram.com/instagram",
			"margin": "0px 0px 0px 0px",
			"facebook": "https://www.facebook.com/quarklyapp/",
			"youtube": "https://www.youtube.com/channel/UCK5bXs2L0bbSMQ82BQ3hIkw",
			"lg-display": "flex",
			"lg-grid-gap": "12px"
		}
	},
	"socialMediaOverride": {
		"kind": "Override",
		"props": {
			"slot": "link-twitter",
			"margin": "0px 0px 0px 5px",
			"children": <div />
		}
	},
	"socialMediaOverride1": {
		"kind": "Override",
		"props": {
			"slot": "link",
			"border-radius": "50%",
			"background": "transparent",
			"hover-color": "--light",
			"display": "flex",
			"margin": "0 5px 0 5px",
			"padding": "5x 5px 5px 5px",
			"width": "32px",
			"height": "32px",
			"align-items": "center",
			"justify-content": "center"
		}
	},
	"socialMediaOverride2": {
		"kind": "Override",
		"props": {
			"slot": "icon",
			"size": "32px",
			"border-radius": "50px",
			"color": "--darkL2"
		}
	},
	"socialMediaOverride3": {
		"kind": "Override",
		"props": {
			"slot": "link-facebook",
			"margin": "0px 5px 0px 0px",
			"children": <div />
		}
	}
};

const Header2 = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" flex-direction="row" justify-content="space-between" />
		<Box {...override("box")}>
			<LinkBox {...override("linkBox")}>
				<Text {...override("text")} />
			</LinkBox>
		</Box>
		<Image {...override("image")} />
		<Box {...override("box1")}>
			<LinkBox {...override("linkBox1")}>
				<Text {...override("text1")} />
			</LinkBox>
		</Box>
		<QuarklycommunityKitMobileSidePanel {...override("quarklycommunityKitMobileSidePanel")}>
			<Override {...override("quarklycommunityKitMobileSidePanelOverride")} />
			<Override {...override("quarklycommunityKitMobileSidePanelOverride1")} />
			<Override {...override("quarklycommunityKitMobileSidePanelOverride2")} />
			<Override {...override("quarklycommunityKitMobileSidePanelOverride3")} />
			<Override {...override("quarklycommunityKitMobileSidePanelOverride4")} />
			<Override {...override("quarklycommunityKitMobileSidePanelOverride5")} />
			<Button {...override("button")}>
				EBOOK
			</Button>
			<Button {...override("button1")}>
				DONATE
			</Button>
			<Box {...override("box2")}>
				<Box {...override("box3")}>
					<LinkBox {...override("linkBox2")}>
						<Icon {...override("icon")} />
						<Text {...override("text2")} />
					</LinkBox>
				</Box>
				<Box {...override("box4")}>
					<Link {...override("link")} />
					<SocialMedia {...override("socialMedia")}>
						<Override {...override("socialMediaOverride")} />
						<Override {...override("socialMediaOverride1")} />
						<Override {...override("socialMediaOverride2")} />
						<Override {...override("socialMediaOverride3")} />
					</SocialMedia>
				</Box>
			</Box>
		</QuarklycommunityKitMobileSidePanel>
		{children}
	</Section>;
};

Object.assign(Header2, { ...Section,
	defaultProps,
	overrides
});
export default Header2;